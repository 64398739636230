@use "@cloudscape-design/design-tokens/index" as awsui;

.full-header {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  padding-top: awsui.$space-static-xxl;
  padding-bottom: awsui.$space-static-xl;
  background-color: awsui.$color-background-layout-main;
}

.inner-header {
  max-width: 1200px;
  margin-left: awsui.$space-scaled-m;
  margin-right: awsui.$space-scaled-m;
}

.header-background-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center center;
}
