html,
body {
  background-color: #000716 !important;
  color: white;
  margin: 0;
  padding: 0;
  height: 100%;
}

.awsui_message-area_cdujn_18ie7_45.awsui_chat-bubble-type-incoming_cdujn_18ie7_62:not(
    #\9
  ) {
  width: 100% !important;
}

.app {
  text-align: center;
}

.action-container {
  position: absolute;
  right: 20px;
  top: 20px;
}

.chat-input-container {
  z-index: 1;
  text-align: center;
}

.chat-footer {
  z-index: 1;
  text-align: left;
}

.tools {
  position: fixed;
  width: 518px;
  bottom: 0px;
  padding: 13px;
  z-index: 10;
  padding-bottom: 80px;
  padding-top: 30px;
}

.help-panel-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 300px;
}

.custom-help-panel {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.help-panel-textarea {
  flex-shrink: 0;
  height: 100px;
  padding: 1rem;
  border-top: 1px solid #ddd;
  background: #1a1a1a;
}

.awsui_help-panel_1d237_17b17_177 hr:not(#\9) {
  border-block-start: 0px !important;
}

.awsui_top-navigation_k5dlb_31br2_163:not(#\9) {
  background: #000d28 !important;
}

.awsui_root_14iqq_1kla9_185.awsui_variant-default_14iqq_1kla9_229.awsui_refresh_14iqq_1kla9_237:not(
    #\9
  ),
.awsui_root_14iqq_1kla9_185.awsui_variant-stacked_14iqq_1kla9_229.awsui_refresh_14iqq_1kla9_237:not(
    #\9
  ) {
  border-block: 0px !important;
  border-inline: 0px !important;
}

.awsui_tools-container_hyvsj_17ek5_980 {
  --awsui-tools-width-g964ok: 600px !important
;
}

[data-amplify-authenticator][data-variation="modal"] {
  padding: 32px 0;
  background-color: #000716;
  background-size: cover;
  background-position: center;
}

.awsui_box_18wu0_1elzt_219.awsui_h1-variant_18wu0_1elzt_223.awsui_color-default_18wu0_1elzt_219:not(
    #\9
  ),
.awsui_box_18wu0_1elzt_219.awsui_h2-variant_18wu0_1elzt_223.awsui_color-default_18wu0_1elzt_219:not(
    #\9
  ),
.awsui_box_18wu0_1elzt_219.awsui_h3-variant_18wu0_1elzt_223.awsui_color-default_18wu0_1elzt_219:not(
    #\9
  ),
.awsui_box_18wu0_1elzt_219.awsui_h4-variant_18wu0_1elzt_223.awsui_color-default_18wu0_1elzt_219:not(
    #\9
  ),
.awsui_box_18wu0_1elzt_219.awsui_h5-variant_18wu0_1elzt_223.awsui_color-default_18wu0_1elzt_219:not(
    #\9
  ) {
  color: white !important;
}

.authenticator-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.authenticator-header img {
  margin: 20px;
}
.authenticator-header .divider {
  width: 1px;
  height: 80px;
  background-color: black;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  order: 0;
  flex-grow: 10;
  margin-right: 0;
  padding-top: "1.0%";
  padding-bottom: "1.0%";
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 728px;
}

.select-filter {
  max-width: 130px;
  flex-grow: 2;
  width: auto;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.upload-and-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-container {
  flex-grow: 1;
  margin-right: 1rem;
}

textarea {
  resize: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.awsui_progress-container_11huc_106uy_272:not(#\9) {
  max-width: none !important;
}
.awsui_header_1d2i7_exyuv_344:not(#\9) {
  background-color: #000716 !important;
}
.app-header {
  background-color: #000716;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 25px;
}

.footer-logo {
  float: left;
  margin-left: 25px;
  margin-bottom: 15px;
  width: 180px;
  min-width: 150px;
}

.app-link {
  color: #61dafb;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
}

.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
  /* margin-bottom: 1em; */
}
.awsui_header_14iqq_v4tpg_263:not(#\9) {
  background-color: #000716 !important;
  background: #000716 !important;
}
.awsui_header-cell_1spae_16w7c_99:not(#\9) {
  background-color: #000716 !important;
  background: #000716 !important;
}
.centered-content {
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.column-content {
  display: flex;
  flex-direction: column; /* Aligns children (text and image) in a column */
  justify-content: space-between; /* This will push the text to the top and the image to the bottom */
  height: 100%; /* Ensures each column-content uses full available height */
}

.image-section {
  margin-top: 4%;
  display: flex; /* Enables flexbox layout */
  flex-wrap: wrap; /* Allows items to wrap to next line if not enough space */
  gap: 10px; /* Spacing between images */
  justify-content: flex-start; /* Aligns images to the start of the flex container */
  align-items: center; /* Center align images vertically */
}

.image-section img {
  width: auto; /* Adjusts the width of images based on their natural aspect ratio */
  max-width: 100%; /* Ensures images do not overflow their container */
  height: auto; /* Maintains aspect ratio */
}

.awsui_root_wih1l_qzfuf_103:not(#\9) {
  background-color: #000716 !important;
  background: #000716 !important;
}
.awsui_root_14iqq_v4tpg_103.awsui_variant-default_14iqq_v4tpg_147.awsui_refresh_14iqq_v4tpg_155:not(
    #\9
  ),
.awsui_root_14iqq_v4tpg_103.awsui_variant-stacked_14iqq_v4tpg_147.awsui_refresh_14iqq_v4tpg_155:not(
    #\9
  ) {
  border-block: none !important;
  border-inline: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
