.flex {
  display: flex;
  flex: 1;
}

.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}

.container {
  position: relative;
  z-index: 10000;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 10px;
  background: #000d28;
  color: #d1d5db;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.footer-image {
  margin-right: 16px;
}

img {
  margin-right: 0px;
}

a {
  text-decoration: none;
  color: #d1d5db;
}
.copyright {
  text-align: right;
  max-width: 220px;
}

.font-size-10 {
  font-size: 10px;
}
